import "regenerator-runtime/runtime";
import { ethers } from "ethers";
import { parseUnits, hexlify } from "ethers/lib/utils";

let provider;
let signer;


const ContractAddresses = {
  "421613": {
    signatureContract: "0x0230d92574F8C17C82e692D82dA72d596b2eAe7C"
  }
}

document.addEventListener("DOMContentLoaded", loadApp());

async function loadApp() {
  provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  signer = provider.getSigner();
  if (!signer) window.location.reload();
  await provider.send("eth_requestAccounts", []);
  processAction();
}


const signSignatureWithMessage = async (chainId, action, to, message, amount) => {

  displayResponse(`Signing signature with message`);
  const nonce = await signer.getTransactionCount('latest');
  displayResponse(`Obtained transaction count: ${nonce}`);

  const domain = {
    name: 'Astrium RPG',
    version: 'V4',
    chainId,
    verifyingContract: ContractAddresses[chainId]?.signatureContract
  };
  const types = {
    Message: [
      { name: 'action', type: 'string' },
      { name: 'to', type: 'address' },
      { name: 'message', type: 'string' },
      { name: 'amount', type: 'string' }
    ]
  };

  const value = {
    action: action.toString(),
    to: to.toString(),
    message: message.toString(),
    amount: amount.toString(),
  };

  console.log(domain);
  console.log(types);
  console.log(value);
  console.log(signer);
  try {
    displayResponse(`Signing typed data`);
    // eslint-disable-next-line no-underscore-dangle
    const signature = await signer._signTypedData(domain, types, value);

    console.log({ signature });
    displayResponse("Signature complete.<br><br>Copy to clipboard then continue to App", signature);
  } catch (error) {
    copyToClipboard("error");
    console.log(error);
    displayResponse(`Signature Denied: ${error}`);
  }
};

async function processAction() {
  const urlParams = new URLSearchParams(window.location.search);
  const action = urlParams.get("action");
  const message = urlParams.get("message");
  const chainId = urlParams.get("chainId") || 1;
  const to = urlParams.get("to");
  const value = urlParams.get("value");
  const data = urlParams.get("data") || "";
  const gasLimit = urlParams.get("gasLimit") || undefined;
  const gasPrice = urlParams.get("gasPrice") || undefined;
  const signAction = urlParams.get("signAction");
  const amount = urlParams.get("amount");

  displayResponse(`Processing Action: ${action}`);

  if (action === "sign" && message) {
    await signMessage(message);
  }
  else if (action === "signTypedData" && signAction && to && message && amount) {
    await signSignatureWithMessage(chainId, signAction, to, message, amount);
  }
  else if (action === "send" && to && value) {
    await sendTransaction(chainId, to, value, gasLimit, gasPrice, data);
  } else {
    displayResponse("Invalid URL");
  }
}

async function sendTransaction(chainId, to, value, gasLimit, gasPrice, data) {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const network = await provider.getNetwork();
    if (network.chainId !== chainId) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${parseInt(chainId, 10).toString(16)}` }], // chainId must be in hexadecimal numbers
      });
    }
    const from = await signer.getAddress();
    const tx = await signer.sendTransaction({
      from,
      to,
      value: parseUnits(value, "wei"),
      gasLimit: gasLimit ? hexlify(Number(gasLimit)) : gasLimit,
      gasPrice: gasPrice ? hexlify(Number(gasPrice)) : gasPrice,
      data: data ? data : "0x",
    });
    console.log({ tx });
    displayResponse("Transaction sent.<br><br>Copy to clipboard then continue to App", tx.hash);
  } catch (error) {
    copyToClipboard("error");
    displayResponse("Transaction Denied");
  }
}

async function signMessage(message) {
  try {
    const signature = await signer.signMessage(message);
    console.log({ signature });
    displayResponse("Signature complete.<br><br>Copy to clipboard then continue to App", signature);
  } catch (error) {
    copyToClipboard("error");
    displayResponse("Signature Denied");
  }
}

async function copyToClipboard(response) {
  try {
    // focus from metamask back to browser
    window.focus();
    // wait to finish focus
    await new Promise((resolve) => setTimeout(resolve, 500));
    // copy tx hash to clipboard
    await navigator.clipboard.writeText(response);
    document.getElementById("response-button").innerHTML = "Copied";
  } catch {
    // for metamask mobile android
    const input = document.createElement("input");
    input.type = "text";
    input.value = response;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    input.style = "visibility: hidden";
    document.getElementById("response-button").innerHTML = "Copied";
  }
}

function displayResponse(text, response) {
  // display error or response
  const responseText = document.getElementById("response-text");
  responseText.innerHTML = text;
  responseText.className = "active";

  if (response) {
    // display button to copy tx.hash or signature
    const responseButton = document.getElementById("response-button");
    responseButton.className = "active";
    responseButton.onclick = () => copyToClipboard(response);
  }
}
